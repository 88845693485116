.App {
  text-align: center;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#mobileshow{display: none;}

@media only screen and (max-width: 768px) {
  #mobile{ display:none;}
  #mobileshow{display: block;}
} 


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.mobileview{display:block;}
    
.desktopview{display:block;}

@media (min-width: 768px) {
    .mobileview{ display:none;}
        
}    

@media (max-width: 768px) {
    .desktopview{ display:none;}
} 

.text-left{
  text-align: left !important;
  color: white;
}
.text-center{
  text-align: center !important;
}
.text-right{
  text-align: right !important;
}
