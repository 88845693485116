.mobileview{display:block;}
    
.desktopview{display:block;}

@media (min-width: 768px) {
    .mobileview{ display:none !important} 
        
}    

@media (max-width: 768px) {
    .desktopview{ display:none !important}
} 


ul#bottommenu li {
    float: left;
    text-decoration: none;
    color:white;
    padding-left: 15px;    
    padding-right: 15px;
    text-align: center;
  }

  ul#bottommenu li a{
      text-decoration: none;
      color:white;
      
  }